*{
    box-sizing: border-box;
}

body{
    margin: 0;
}

.nav{
    background-color: #608cdf;
    color: white;
    display: flex;
    /* justify-content: space-between; */
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
    position: sticky;
    top:5px;
    z-index: 999;
}

.site-title {
    font-size: 2rem;
    color: #ffffff;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav li.active {
     background-color: #8dabe2;
}
.nav li:hover {
    background-color: #ffffff;
    color: #608cdf;
}