.itemsubmission-wrapperZ {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2%;

  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  
  
}
.itemsubmission-wrapperZ form {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  border-radius: 10px;
  background-color: #8dabe2;
  padding: 10px 0px;
  overflow-y: auto;
  box-shadow: 12px 12px 2px 1px rgba(255, 255, 255, 0.2);
}
.itemsubmission-wrapperZ
  form
  label:not(.itemsubmission-form-image-selection-label) {
  display: flex;
  width: 90%;
  margin: 2px 5%;
  justify-content: left;
  align-items: center;
  gap: 1em;
}
.itemsubmission-wrapper form label span,
.itemsubmission-customfield-container span {
  min-width: 120px;
  word-wrap: break-word;
}
.itemsubmission-wrapper form label input,
#tags,
.itemsubmission-form-image-selection-label,
.itemsubmission-wrapper form label textarea {
  width: 75%;
  padding: 12px 20px;
  margin: 8px 0;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1em;
  font-family:Arial, Helvetica, sans-serif;
  resize:vertical;
}

.itemsubmission-form-image-selection-label{
  font-size:medium;
  font-family: 'Courier New', Courier, monospace;
  color: grey;
}

::placeholder{
  font-size:medium;
  font-family: 'Courier New', Courier, monospace;
  color: grey;
}

.tags-input-container {
  display: flex;
  width: 75%;
  background-color: #ffffff;
  padding: 5px 2px;
}

.itemsubmission-customfield-container div {
  display: inline-block;
  width: 75%;
}

.itemsubmission-customfield-container div div {
  display: flex;
  align-items: center;
  gap: 1em;
  width: 100%;
}

.itemsubmission-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.itemsubmission-button-container button {
  border: none;
  min-height: 40px;
  width: 130px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(121, 190, 250);
  height: auto;
}

.itemsubmission-button-container button:hover {
  border: none;
  height: 45px;
  width: 135px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px #ffffff;
}

.itemsubmission-button-container button:active {
  background-color: rgb(92, 178, 253);
  border: none;
  height: 40px;
  width: 130px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(121, 190, 250);
}

.itemsubmission-button-container button:first-child {
  margin-right: 40px;
}

.itemsubmission-form-image {
  display: flex;
  position: relative;
  justify-content: center;
}

img{
    border-radius: 10%;
}

.itemsubmission-form-image-selection {
  display: none !important;
}

.itemsubmission-image-deletebtn {
  position: absolute;
  background-color: rgb(132, 198, 255, .75);
  border-radius: 16px;
  bottom: 10px;
  justify-content: center;
  width: 25%;
  height:10%;
  align-items: center;
  box-shadow: 0px 0px 2px 2px rgb(121, 190, 250);
  border: none;
}

.itemsubmission-image-deletebtn:hover {
box-shadow: none;
}

.itemsubmission-image-deletebtn:active {
  background-color: rgba(101, 183, 255, 0.75);
  box-shadow: 0px 0px 2px 2px rgb(121, 190, 250);
  }

.itemsubmission-errormessage{
  display: flex;
  justify-content: center;
  color:red;
  
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;

  color: fieldtext !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active,
input:-internal-autofill-selected,
input:-internal-autofill-previewed {
    background-color: rgb(231, 229, 229) !important;
    -webkit-box-shadow: 0 0 0 30px  rgb(231, 229, 229) inset !important;
}
