.login-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2%;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1001;
  @media (max-width: 600px) {
    height: 100lvh;
  }
}

.login-popup-inner {
  padding: 32px;
  width: 100%;
  max-width: 640px;
  background-color: #8dabe2;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 12px 12px 2px 1px rgba(255, 255, 255, 0.2);
  /* overflow-y: auto; */
  overscroll-behavior: none;
  @media (max-width: 600px) {
    height: 100lvh;
  }
}

.login-popup .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.login-popup button {
  border-radius: 30px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 0px 10px;
  width: auto;
  height: 30px;
  color: #608cdf;
  margin-right: 10px;
  background-color: #ffffff;
  &:hover {
    opacity: 0.9;
    transform: scale(0.98);
  }
}

.login__inputField {
  border-radius: 10px;
  height: 30px;
  border-color: #608cdf;
  width: 50%;
  min-width: 250px;
  margin-bottom: 15px;
  font-size: 16px;
}

.login__fieldName {
  margin-bottom: 5px;
}

.move-item-description {
  margin-bottom: 15px;
}

div[data-lastpass-icon-root]{
  display: none !important;;
}

input{
  font-size:16px;
}