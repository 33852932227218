.tags-input-container{
    border-radius: 3px;
    align-items: center;
    flex-wrap: wrap;
    gap:.5em
}
.tags-input-container .tag-item{
    background-color: #8dabe2;
    display: inline-block;
    padding:.5em .75em;
    border-radius: 20px;
}
.tag-item .close{
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    cursor: pointer;
}

.tags-input{
    width: 100% !important;
    border: none;
}


