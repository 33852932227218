.children{
    display: none;
}
ul {
    list-style: none;
}

span{
    cursor: grabbing;
}

.expandable{
    cursor:pointer
}